<template>

  <div class="container-fluid">

    <div class="row g-0">
      <div class="col-6">
        <div class="input-group h-100">
          <span class="input-group-text">{{ store.english.langCode }}</span>

              <textarea disabled="disabled" v-model="getEnglishTranslationRaw"
                        class="form-control resize-none"
                        ref="englishInput"/>
        </div>
      </div>
      <div class="col-6">
        <FormattedMinecraftText :text="getEnglishTranslation" class="h-100"/>
      </div>
    </div>
    <div class="row g-0">
      <!--      <div class="input-group">-->
      <!--        <span class="input-group-text">{{ store.locale.langCode }}</span>-->
      <!--        <input type="text" :value="getLocaleTranslation"-->
      <!--               class="form-control"-->
      <!--               @input="save($event, 'locale')"-->
      <!--               @change="changeInput($event, 'localeInput')"-->
      <!--               @focusin="changeInputFocusIn($event, 'localeInput')"-->
      <!--               @click="changeInput($event, 'localInput')"-->
      <!--               ref="localeInput"/>-->
      <!--      </div>-->
      <!--    </div>-->
      <!--    <div class="col-6">-->
      <!--      <FormattedMinecraftText :text="getEnglishTranslation" class="mb-1"/>-->
      <!--            <FormattedMinecraftText :text="getLocaleTranslation"/>-->
      <!--    </div>-->
      <div class="col-6">
        <div class="input-group h-100">
          <span class="input-group-text">{{ store.locale.langCode }}</span>
          <textarea v-model="getLocaleTranslationRaw"
                    class="form-control resize-none"
                    ref="localeInput"
                    @input="save($event, 'locale')"
                    @change="changeInput($event, 'localeInput')"
                    @focusin="changeInputFocusIn($event, 'localeInput')"
                    @click="changeInput($event, 'localInput')"
          />
        </div>
      </div>
      <div class="col-6">
        <FormattedMinecraftText :text="getLocaleTranslation" class="h-100"/>
      </div>
    </div>
  </div>
</template>
<script>
import store from '@/store/lang';
import FormattedMinecraftText from '@/components/FormattedMinecraftText.vue';

export default {
  name: 'InputField',
  components: {
    FormattedMinecraftText,
  },
  props: {
    path: {
      type: Array,
      default: () => [],
    },
    langKey: {
      type: String,
      default: '',
    },
    lang: {
      type: String,
      default: 'english',
    },
  },
  data() {
    return {
      store: store.state,
    };
  },
  methods: {
    getLang(path = [], lang = 'english') {
      return this.$op.get([
        'store',
        lang,
        'decoded',
        ...path,
        this.langKey,
      ]);
    },
    save(event, lang) {
      this.changeInput(event);
      this.deepSet(this.store, [
        lang,
        'decoded',
        ...this.path,
        this.langKey,
      ], event.target.value);

      this.saveToStorage(lang);
    },
    saveToStorage(lang) {
      localStorage.setItem(`translationFile${this.store[lang].langCode}`, JSON.stringify(this.store[lang].decoded));
    },
    deepSet(obj, path, value) {
      if (typeof path === 'string') {
        path = path.split('.');
      }

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < path.length; i++) {
        if (i === (path.length - 1)) {
          obj[path[i]] = value;
        } else {
          // Check if the path exists if not create it
          if (!obj[path[i]]) {
            obj[path[i]] = {};
          }
          obj = obj[path[i]];
          this.deepSet(obj[path[i]], path.unshift(), value);
        }
      }
    },
    changeInput(event) {
      this.store.editor.cursor = event.target.selectionStart;
    },
    changeInputFocusIn(event, ref) {
      const lang = ref.replace('Input', '');
      this.store.editor.cursor = event.target.selectionStart;
      this.store.editor.ref = this.$refs[ref];
      this.store.editor.path = [
        lang,
        'decoded',
        ...this.path,
        this.langKey,
      ];

      this.saveToStorage(lang);
    },
  },
  computed: {
    getEnglishTranslation() {
      return this.getLang(this.path, 'english');
    },
    getEnglishTranslationRaw() {
      return this.getLang(this.path, 'english')
        .replace('\n', '\\n');
    },
    getLocaleTranslation() {
      return this.getLang(this.path, 'locale');
    },
    getLocaleTranslationRaw() {
      return this.getLang(this.path, 'locale');
    },
  },
};
</script>
