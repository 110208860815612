const icons = {
  e089: 'ShortEliteTag',
  e08a: 'ShortPlayerTag',
  e08b: 'ShortStaffTag',
  e08c: 'ShortHelperTag',
  e08d: 'ShortVIPTag',
  e08e: 'ShortUltraTag',
  e08f: 'ShortInfluencerTag',
  e096: 'EliteUltraTag',
  e097: 'FreeTag',
  e098: 'PremiumTag',
  e099: 'EliteTag',
  e09a: 'PlayerTag',
  e09b: 'StaffTag',
  e09c: 'HelperTag',
  e09d: 'VIPTag',
  e09e: 'UltraTag',
  e09f: 'InfluencerTag',
  e0a9: 'ShortNoticeChannel',
  e0aa: 'ShortJoinChannel',
  e0ab: 'ShortWarnChannel',
  e0ac: 'ShortInfoChannel',
  e0ad: 'ShortGameChannel',
  e0ae: 'ShortTeamChannel',
  e0af: 'ShortPartyChannel',
  e0b9: 'NoticeChannel',
  e0ba: 'JoinChannel',
  e0bb: 'WarnChannel',
  e0bc: 'InfoChannel',
  e0bd: 'GameChannel',
  e0be: 'TeamChannel',
  e0bf: 'PartyChannel',
  e100: 'Food',
  e101: 'ArmourRegular',
  e102: 'Minecoin',
  e103: 'EDUGolem',
  e104: 'TextToSpeech',
  e105: 'Minetoken',
  e106: 'Armour1',
  e107: 'Armour2',
  e108: 'Armour3',
  e109: 'Armour4',
  e10a: 'Armour5',
  e10b: 'Heart',
  e10c: 'Absorption',
  e10d: 'WoodenSword',
  e10e: 'StoneSword',
  e10f: 'IronSword',
  e110: 'GoldenSword',
  e111: 'DiamondSword',
  e112: 'NetheriteSword',
  e113: 'Bow1',
  e114: 'Bow2',
  e115: 'Bow3',
  e116: 'Bow4',
  e117: 'Bow5',
  e118: 'BowRegular',
  e119: 'Snowball',
  e11a: 'Fire',
  e11b: 'Fall',
  e11c: 'Drown',
  e11d: 'WoodenAxe',
  e11e: 'StoneAxe',
  e11f: 'IronAxe',
  e120: 'GoldenAxe',
  e121: 'DiamondAxe',
  e122: 'NetheriteAxe',
  e123: 'WoodenPickaxe',
  e124: 'StonePickaxe',
  e125: 'IronPickaxe',
  e126: 'GoldenPickaxe',
  e127: 'DiamondPickaxe',
  e128: 'NetheritePickaxe',
  e129: 'Cactus',
  e12a: 'Explosion',
  e12b: 'AnvilFall',
  e12c: 'Trident',
  e12d: 'Firework',
  e12e: 'Fist',
  e12f: 'Anvil',
  e130: 'Xbox',
  e131: 'NintendoSwitch',
  e132: 'Other',
  e133: 'PlayStation',
  e134: 'Android',
  e135: 'IOS',
  e136: 'RedSkull',
  e137: 'Skull',
  e138: 'Hourglass',
  e139: 'Clock',
  e13a: 'Ping5',
  e13b: 'Ping4',
  e13c: 'Ping3',
  e13d: 'Ping2',
  e13e: 'Ping1',
  e13f: 'Bush',
  e140: 'IronIngot',
  e141: 'GoldenIngot',
  e142: 'Netherite',
  e143: 'Diamond',
  e144: 'Emerald',
  e145: 'GoldenNugget',
  e146: 'Team',
  e147: 'WoodenBlock',
  e148: 'Bounty',
  e149: 'BountyComplete',
  e14a: 'BountyFail',
  e14b: 'SoulIcon',
  e14c: 'Ruler',
  e150: 'BlackTeam',
  e151: 'DarkBlueTeam',
  e152: 'DarkGreenTeam',
  e153: 'DarkAquaTeam',
  e154: 'DarkRedTeam',
  e155: 'DarkPurpleTeam',
  e156: 'GoldTeam',
  e157: 'GrayTeam',
  e158: 'DarkGrayTeam',
  e159: 'BlueTeam',
  e15a: 'GreenTeam',
  e15b: 'AquaTeam',
  e15c: 'RedTeam',
  e15d: 'LightPurpleTeam',
  e15e: 'YellowTeam',
  e15f: 'WhiteTeam',
  e160: 'BlackTeamOutline',
  e161: 'DarkBlueTeamOutline',
  e162: 'DarkGreenTeamOutline',
  e163: 'DarkAquaTeamOutline',
  e164: 'DarkRedTeamOutline',
  e165: 'DarkPurpleTeamOutline',
  e166: 'GoldTeamOutline',
  e167: 'GrayTeamOutline',
  e168: 'DarkGrayTeamOutline',
  e169: 'BlueTeamOutline',
  e16a: 'GreenTeamOutline',
  e16b: 'AquaTeamOutline',
  e16c: 'RedTeamOutline',
  e16d: 'LightPurpleTeamOutline',
  e16e: 'YellowTeamOutline',
  e16f: 'WhiteTeamOutline',
  e170: 'BlackSolo',
  e171: 'DarkBlueSolo',
  e172: 'DarkGreenSolo',
  e173: 'DarkAquaSolo',
  e174: 'DarkRedSolo',
  e175: 'DarkPurpleSolo',
  e176: 'GoldSolo',
  e177: 'GraySolo',
  e178: 'DarkGraySolo',
  e179: 'BlueSolo',
  e17a: 'GreenSolo',
  e17b: 'AquaSolo',
  e17c: 'RedSolo',
  e17d: 'LightPurpleSolo',
  e17e: 'YellowSolo',
  e17f: 'WhiteSolo',
  e180: 'BlackSoloOutline',
  e181: 'DarkBlueSoloOutline',
  e182: 'DarkGreenSoloOutline',
  e183: 'DarkAquaSoloOutline',
  e184: 'DarkRedSoloOutline',
  e185: 'DarkPurpleSoloOutline',
  e186: 'GoldSoloOutline',
  e187: 'GraySoloOutline',
  e188: 'DarkGraySoloOutline',
  e189: 'BlueSoloOutline',
  e18a: 'GreenSoloOutline',
  e18b: 'AquaSoloOutline',
  e18c: 'RedSoloOutline',
  e18d: 'LightPurpleSoloOutline',
  e18e: 'YellowSoloOutline',
  e18f: 'WhiteSoloOutline',
  e190: 'VanityAvatar',
  e191: 'VanityCore',
  e192: 'VanityGravestone',
  e193: 'VanityDropship',
  e194: 'VanityPixelArt',
  e195: 'VanityTaunt',
  e196: 'VanityTitle',
  e197: 'XP',
  e198: 'LevelUp',
  e199: 'XPCoin',
  e19a: 'VanityKart',
  e19b: 'Battlepass',
  e19c: 'FriendSlots',
  e19d: 'ParkourSlots',
  e19e: 'PowerVotes',
  e19f: 'NameColours',
  e1a0: 'Blindness',
  e1a1: 'Freeze',
  e1a2: 'Invisibility',
  e1a3: 'Slowness',
  e1a4: 'Speed',
  e1a5: 'MaxHeal',
  e1a6: 'PropSwap',
  e1a7: 'MirrorImage',
  e1a8: 'Invincible',
  e1a9: 'FakeTaunts',
  e1aa: 'NameTag',
  e1ab: 'Blindness2',
  e1ac: 'Particles',
  e1b0: 'CountdownLight1',
  e1b1: 'CountdownLight2',
  e1b2: 'CountdownLight3',
  e1b3: 'CountdownLight4',
  e1b4: 'CountdownLight5',
  e1b5: 'CheckeredFlag',
  e1b6: 'FirstPlace',
  e1b7: 'SecondPlace',
  e1b8: 'ThirdPlace',
  e1b9: 'Countdown1',
  e1ba: 'Countdown2',
  e1bb: 'Countdown3',
  e1bc: 'RedMinus',
  e1bd: 'YellowPlus',
  e1be: 'GreenPlus',
  e1bf: 'GoldMedal',
  e1c0: 'Battery',
  e1c1: 'Locked',
  e1c2: 'Unlocked',
  e1c3: 'Coin',
  e1c4: 'SonicSnowball',
  e1c5: 'IceSlider',
  e1c6: 'DistanceIndicatorGrey',
  e1c7: 'DistanceIndicatorRed',
  e1c8: 'DistanceIndicatorOrange',
  e1c9: 'DistanceIndicatorYellow',
  e1ca: 'DistanceIndicatorLightBlue',
  e1cb: 'DistanceIndicatorCyan',
  e1cc: 'DistanceIndicatorDarkBlue',
  e1cd: 'Online',
  e1ce: 'Offline',
  e1d0: 'Trophy',
  e1d1: 'Like',
  e1d2: 'Stopwatch',
  e1d3: 'Hatchet',
  e1d4: 'Prestige1',
  e1d5: 'Prestige2',
  e1d6: 'Prestige3',
  e1d7: 'Prestige4',
  e1d8: 'Prestige5',
  e1d9: 'PrestigePlaque1',
  e1da: 'PrestigePlaque2',
  e1db: 'PrestigePlaque3',
  e1dc: 'PrestigePlaque4',
  e1dd: 'PrestigePlaque5',
  e1de: 'Candy',
  e1e0: 'CoinChronos',
  e1e1: 'CoinCoreWars',
  e1e2: 'CoinFillTheGaps',
  e1e3: 'CoinRush',
  e1e4: 'CoinPropHunt',
  e1e5: 'CoinHyperRacers',
  e1e6: 'CoinPlayground',
  e1e7: 'CoinParkour',
  e1e8: 'Gem',
  e1e9: 'Challenges',
  e1ea: 'GemDust',
  e1eb: 'QuestionMark',
  e1ec: 'Reward',
  e1ed: 'Giveaway',
  e1ee: 'CoinFarmingSilver',
  e1ef: 'CoinFarmingGold',
};

export default function replaceIcons(message) {
  if (message) {
    const matches = message.match(/[\uE000-\uF8FF]|\\u[0-9a-fA-F]{4}/gu);
    console.log(matches);
    if (matches) {
      matches.forEach((match) => {
        const code = match.length === 6 ? match.substring(2).toLowerCase()
          : match.charCodeAt(0).toString(16);
        console.log(code, icons[code]);
        message = message.replace(match, `<icon:${icons[code]}>`);
      });
    }
  }
  return message;
}
