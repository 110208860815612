<template>
  <div class="formatting-list" id="layout-grid-formatting-bar">
    <ul>
      <li v-for="(value, key, index) in bar" :key="index">
        <button @click="format(value.command, value.params)">
          <fa :icon="value.icon" v-if="value.icon" class="fa-2x"/>
          {{ value.text }}
        </button>

        <ul v-if="value.children && value.type === 'color'">
          <li v-for="(v, k, i) in value.children" :key="i">
            <button @click="format('color', k)" :class="`background-colour-${k} p-4`">
              {{ v.text || v }}
            </button>
          </li>
        </ul>

        <ul v-if="value.command === 'unicode'">
          <li v-for="(v, k, i) in emoji.available" :key="i">
            <button @click="format('unicode', v.unicode)" :class="`p-4`">
              <img :src="v.imagePath" :alt="v.name">
            </button>
          </li>
        </ul>
      </li>
    </ul>

    <ul class="preview-mode-toggle">
      <li :class="{'active': store.editor.preview === 'dark'}">
        <button @click="setPreview('dark')">
          <fa icon="moon" class="fa-2x"/>
          D
        </button>
      </li>
      <li :class="{'active': !store.editor.preview || store.editor.preview === 'light'}">
        <button @click="setPreview('light')">
          <fa icon="sun" class="fa-2x"/>
          L
        </button>
      </li>
      <li :class="{'active': store.editor.preview === 'background'}">
        <button @click="setPreview('background')">
          <fa icon="home" class="fa-2x"/>
          B
        </button>
      </li>
    </ul>
  </div>
</template>
<script>
import { saveAs } from 'file-saver';
import dot from 'dot-object';
import store from '@/store/lang';
import emotes from '@/lib/emotes';

export default {
  name: 'FormattingBar',
  data() {
    return {
      bar: [
        {
          text: 'Bold',
          command: 'bold',
          icon: 'bold',
        },
        {
          text: 'Italic',
          command: 'italic',
          icon: 'italic',
        },
        {
          text: 'Underline',
          command: 'underline',
          icon: 'underline',
        },
        {
          text: 'Strike',
          command: 'strike',
          icon: 'strikethrough',
        },
        {
          text: 'Magic',
          command: 'magic',
          icon: 'magic',
        },
        {
          text: 'Reset',
          command: 'reset',
          icon: 'remove-format',
        },
        {
          text: 'New Line',
          command: 'newline',
          icon: 'grip-lines',
        },
        {
          text: 'Chevron',
          command: 'chevron',
          icon: 'chevron-right',
        },
        {
          text: 'Color',
          type: 'color',
          icon: 'palette',
          children:
            {
              0: {
                color: '#000000',
                text: '&0',
              },
              1: {
                color: '#0000AA',
                text: '&1',
              },
              2: {
                color: '#00AA00',
                text: '&2',
              },
              3: {
                color: '#00AAAA',
                text: '&3',
              },
              4: {
                color: '#AA0000',
                text: '&4',
              },
              5: {
                color: '#AA00AA',
                text: '&5',
              },
              6: {
                color: '#FFAA00',
                text: '&6',
              },
              7: {
                color: '#AAAAAA',
                text: '&7',
              },
              8: {
                color: '#555555',
                text: '&8',
              },
              9: {
                color: '#0000AA',
                text: '&9',
              },
              a: {
                color: '#55FF55',
                text: '&a',
              },
              b: {
                color: '#55FFFF',
                text: '&b',
              },
              c: {
                color: '#FF5555',
                text: '&c',
              },
              d: {
                color: '#FF55FF',
                text: '&d',
              },
              e: {
                color: '#FFFF55',
                text: '&e',
              },
              f: {
                color: '#FFFFFF',
                text: '&f',
              },
            },
        },
        {
          text: 'Emoji',
          command: 'unicode',
          icon: 'laugh-beam',
        },
        {
          text: 'Download',
          command: 'download',
          icon: 'save',
        },
      ],
      colors: {},
      colorsOpen: true,
      store: store.state,
      emoji: {
        state: null,
        available: emotes,
      },
    };
  },
  methods: {

    format(command, params) {
      const cursor = this.store.editor.cursor;
      const ref = this.store.editor.ref;
      const path = this.store.editor.path;
      let format = null;

      if ((!path || !ref) && command !== 'download') {
        console.error('No path found for formatting');
        return;
      }

      if (this[command] && typeof this[command] === 'function') {
        format = this[command](params);
      } else {
        console.info('No command found', command);
      }

      if (!format) {
        return;
      }

      const value = ref.value;
      const val = [value.slice(0, cursor), format, value.slice(cursor)].join('');
      this.deepSet(this.store, path, val);
      ref.focus();
      this.$nextTick(() => {
        this.store.editor.cursor += format.length;
        ref.setSelectionRange((cursor + (format.length)), (cursor + format.length));
      });
    },
    deepSet(obj, path, value) {
      if (typeof path === 'string') {
        path = path.split('.');
      }

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < path.length; i++) {
        if (i === (path.length - 1)) {
          obj[path[i]] = value;
        } else {
          // Check if the path exists if not create it
          if (!obj[path[i]]) {
            obj[path[i]] = {};
          }
          obj = obj[path[i]];
          this.deepSet(obj[path[i]], path.unshift(), value);
        }
      }
    },
    download() {
      const storeLang = this.store.locale.langCode;
      let file = null;
      let fileType = 'json';

      if (this.store.editor.fileType === 'lang') {
        file = this.encodeFile();
        fileType = 'lang';
      } else if (this.store.editor.fileType === 'json') {
        file = JSON.stringify(this.store.locale.decoded, null, 2);
        file = file.replace(/\\\\n/g, '\\n');
      }

      const blob = new Blob([file]);

      saveAs(blob, `${storeLang}.${fileType}`);

      return false;
    },
    encodeFile() {
      const d = dot.dot(this.store.locale.decoded);
      const arr = [];
      const keys = Object.keys(d);

      for (let i = 0; i < keys.length; i++) {
        arr.push(`${keys[i]}=${d[keys[i]]}`);
      }
      return arr.join('\n');
    },
    bold() {
      return '&l';
    },
    italic() {
      return '&o';
    },
    underline() {
      return '&n';
    },
    strike() {
      return '&m';
    },
    magic() {
      return '&k';
    },
    reset() {
      return '&r';
    },
    newline() {
      return '\n';
    },
    chevron() {
      return '»';
    },
    unicode(unicode) {
      return unicode;
    },
    color(color) {
      return `&${color}`;
    },
    setPreview(mode) {
      this.store.editor.preview = mode;
    },
    openEmoji() {
      this.emoji.available = emotes;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/minecraft.scss";

</style>
