export default [
  {
    name: 'Food',
    imagePath: '/images/emoticons/food.png',
    unicode: '',
    featured: false,
  },
  {
    name: 'Armour',
    imagePath: '/images/emoticons/armour.png',
    unicode: '',
    featured: false,
  },
  {
    name: 'Minecoin',
    imagePath: '/images/emoticons/minecoin.png',
    unicode: '',
    featured: false,
  },
  {
    name: 'Code Builder',
    imagePath: '/images/emoticons/code-builder.png',
    unicode: '',
    featured: false,
  },
  {
    name: 'Nintendo Minus',
    imagePath: '/images/emoticons/nintendo-minus.png',
    unicode: '',
    featured: false,
  },
  {
    name: 'Nintendo Plus',
    imagePath: '/images/emoticons/nintendo-plus.png',
    unicode: '',
    featured: false,
  },
  {
    name: 'Pocket Edition Jump',
    imagePath: '/images/emoticons/jump-button.png',
    unicode: '',
    featured: false,
  },
  {
    name: 'Pocket Edition Crouch',
    imagePath: '/images/emoticons/crouch-button.png',
    unicode: '',
    featured: false,
  },
  {
    name: 'PlayStation D-Pad Up',
    imagePath: '/images/emoticons/ps-d-up.png',
    unicode: '',
    featured: false,
  },
  {
    name: 'PlayStation D-Pad Down',
    imagePath: '/images/emoticons/ps-d-down.png',
    unicode: '',
    featured: false,
  },
  {
    name: 'PlayStation D-Pad Left',
    imagePath: '/images/emoticons/ps-d-left.png',
    unicode: '',
    featured: false,
  },
  {
    name: 'PlayStation D-Pad Right',
    imagePath: '/images/emoticons/ps-d-right.png',
    unicode: '',
    featured: false,
  },
  {
    name: 'Nintendo D-Pad Up',
    imagePath: '/images/emoticons/nintendo-d-up.png',
    unicode: '',
    featured: false,
  },
  {
    name: 'Nintendo D-Pad Down',
    imagePath: '/images/emoticons/nintendo-d-down.png',
    unicode: '',
    featured: false,
  },
  {
    name: 'Nintendo D-Pad Left',
    imagePath: '/images/emoticons/nintendo-d-left.png',
    unicode: '',
    featured: false,
  },
  {
    name: 'Nintendo D-Pad Right',
    imagePath: '/images/emoticons/nintendo-d-right.png',
    unicode: '',
    featured: false,
  },
  {
    name: 'X-BOX A Button',
    imagePath: '/images/emoticons/xbox-a.png',
    unicode: '',
    featured: false,
  },
  {
    name: 'X-BOX B Button',
    imagePath: '/images/emoticons/xbox-b.png',
    unicode: '',
    featured: false,
  },
  {
    name: 'X-BOX X Button',
    imagePath: '/images/emoticons/xbox-x.png',
    unicode: '',
    featured: false,
  },
  {
    name: 'X-BOX Y Button',
    imagePath: '/images/emoticons/xbox-y.png',
    unicode: '',
    featured: false,
  },
  {
    name: 'PlayStation Cross Button',
    imagePath: '/images/emoticons/ps-x.png',
    unicode: '',
    featured: false,
  },
  {
    name: 'PlayStation Circle Button',
    imagePath: '/images/emoticons/ps-o.png',
    unicode: '',
    featured: false,
  },
  {
    name: 'PlayStation Square Button',
    imagePath: '/images/emoticons/ps-square.png',
    unicode: '',
    featured: false,
  },
  {
    name: 'PlayStation Triangle Button',
    imagePath: '/images/emoticons/ps-triangle.png',
    unicode: '',
    featured: false,
  },
];
