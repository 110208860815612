<template>
  <TranslationView />
</template>

<script>
import TranslationView from '@/pages/translations/TranslationView.vue';
import store from '@/store/lang';

export default {
  name: 'App',
  components: {
    TranslationView,
  },
  watch: {
    mode(n, o) {
      console.log('Mode changed', o, n);
      document.body.classList.remove(`${o}-mode`);
      document.body.classList.add(`${n}-mode`);
    },
  },
  mounted() {
    document.body.classList.add(`${this.mode}-mode`);
  },
  data() {
    return {
      store: store.state,
    };
  },
  computed: {
    mode() {
      return this.store.editor.theme;
    },
  },
};
</script>
<style lang="scss">
@import "./assets/scss/app.scss";
</style>
