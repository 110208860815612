<template>
  <template v-for="(value, key, index) in translation" :key="index" class="position-relative">

    <template v-if="typeof value === 'object'">
      <!--      <div class="anchor" :id="id(key)"></div>-->
      <div
          class="translation-section translation-tree"
          :id="id(key)">

        <component :is="`h${depth < 7 ? depth : 6}`">
          {{ key }}
        </component>
        <TranslationTree :translation="value"
                         :path="[...path, key]"
                         :depth="depth + 1"/>
      </div>
    </template>
    <template v-else class="translation-row">
      <h6 class="translation-row-header p-3">{{ [...path, key].join('.') }}</h6>

      <InputField :path="path" :langKey="key" class="my -2"/>
    </template>
  </template>
</template>

<script>
import store from '@/store/lang';
import InputField from '@/components/InputField.vue';

export default {
  name: 'TranslationTree',
  components: {
    InputField,
  },
  data() {
    return {
      store: store.state,
    };
  },
  props: {
    translation: {
      type: Object,
      default: () => ({}),
    },
    path: {
      type: Array,
      default: () => ([]),
    },
    language: {
      type: String,
      default: '',
    },
    depth: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    id(key) {
      return [...this.path, key].join('-');
    },
  },
  computed: {},
};
</script>
<style scoped>
.anchor {
  top: -110px;
  visibility: hidden;
  position: absolute;
}
</style>
