<template>
  <input type="file"
         @dragover="hover = true"
         @dragleave="hover = false"
         @change="addFile"
         class="customUploadBox">

  <h3 v-if="!hover">{{ file.name }}</h3>

  <div
    class="drop-active d-flex justify-content-center align-items-center h-100 w-100
    border-success border bg-dark text-white" v-if="hover">
    <div>
      <h3>Drop file here to upload</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FileUpload',
  data() {
    return {
      hover: false,
      file: {},
    };
  },
  emits: [
    'file',
  ],
  methods: {
    addFile(e) {
      e.preventDefault();
      if (e.target instanceof HTMLInputElement) {
        this.file = e.target.files[0];
      } else {
        this.file = e.dataTransfer.files[0];
      }
      this.$emit('file', this.file);
      this.hover = false;
    },
  },
};
</script>
