<template>
  <div
    v-html="formatText(text)"
    class="minecraft-style-preview"
    :class="[mode]"
  />

</template>

<script>
import store from '@/store/lang';
import { convertColorCodesToHTML } from '@/plugins/minecraft';

export default {
  name: 'FormattedMinecraftText',
  props: {
    text: {
      default: () => '',
    },
  },
  data() {
    return {
      store: store.state,
    };
  },
  computed: {
    rankColor() {
      switch (this.rank) {
        case 'Staff':
          return 'light-blue darken-1';
        case 'VIP':
          return 'yellow lighten-2';
        case 'Influencer':
          return 'deep-orange';
        case 'Helper':
          return 'green darken-1';
        default:
          return 'grey';
      }
    },
    mode() {
      return this.store.editor.preview || '';
    },
  },
  methods: {

    formatText(text) {
      return convertColorCodesToHTML(text);
    },
  },
};
</script>
