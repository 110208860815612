import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faStrikethrough,
  faBold,
  faItalic,
  faUnderline,
  faMagic,
  faGripLines,
  faRemoveFormat,
  faChevronRight,
  faLaughBeam,
  faPalette,
  faSave,
  faSun,
  faMoon,
  faHome,
  faSpinner,
  faGlobe,
  faRedo,
  faShare,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faStrikethrough,
  faBold,
  faItalic,
  faUnderline,
  faMagic,
  faGripLines,
  faRemoveFormat,
  faChevronRight,
  faLaughBeam,
  faPalette,
  faSave,
  faSun,
  faMoon,
  faHome,
  faSpinner,
  faGlobe,
  faRedo,
  faShare,
  faTrashAlt,
);

export default FontAwesomeIcon;
