<template>
  <div
    class="translation-upload-box d-flex w-50 m-3 align-items-center justify-content-center
    flex-column" :class="{
                    'border border-success bg-success': getStore.state === 'pending'
                  }">
    <div class="text-center" v-if="getStore.state !== 'pending'">
      Drop your {{ lang }} translation file here
    </div>
    <FileUpload @file="addFile"/>

    <p v-if="getStore.cached">(cached)</p>
  </div>
</template>
<script>
import FileUpload from '@/components/FileUpload.vue';
import store from '../store/lang';

export default {
  name: 'TranslationDropBox',
  components: { FileUpload },
  data() {
    return {
      store: store.state,
    };
  },
  props: {
    lang: {
      type: String,
      required: true,
    },
    source: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    addFile(file) {
      console.log('adding file');
      if (!/^[a-z]{2}_[a-z]{2}.(json|lang)$/.test(file.name)) {
        if (this.source && (file.name !== 'en_gb.json' && file.name !== 'en_GB.lang')) {
          this.getStore.state = 'fail';
          return;
        }

        this.getStore.state = 'invalid';
      } else {
        this.getStore.fileBase = file;
        this.translationType = file.name.split('.')
          .slice(-1);
        this.$emit('fileUpload', file);
        this.getStore.state = 'pending';
      }
    },
  },
  computed: {
    getStore() {
      return this.store[
        this.lang
      ];
    },
  },
};
</script>
