<template>
  <div>
    <template v-for="(value, key, index) in translation">
      <li v-if="typeof value === 'object' && depth < 2" :key="index">
        <template v-if="typeof value === 'object'">
          <a :href="'#' + id(key)" @click.prevent=goto(id(key))
             :class="{'active': depth === 0 && index === 0}">
            {{ key }}
          </a>

          <ul class="">
            <NavigationTree :translation="value"
                            :path="[...path, key]" :depth="depth + 1"/>
          </ul>
        </template>
      </li>
    </template>
  </div>
</template>

<script>
import store from '@/store/lang';

export default {
  name: 'NavigationTree',
  data() {
    return {
      store: store.state,
    };
  },
  props: {
    translation: {
      type: Object,
      default: () => ({}),
    },
    path: {
      type: Array,
      default: () => ([]),
    },
    language: {
      type: String,
      default: '',
    },
    depth: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    id(key) {
      return [...this.path, key].join('-');
    },
    goto(key) {
      console.log({ key });
      const section = document.getElementById(key);
      const container = document.getElementsByTagName('html')[0];
      console.log(container, section);

      window.scrollSpy.scrolling = true;

      window.location.hash = key;
      container.scrollTop = (section.offsetTop);

      window.scrollSpy.scrolling = false;
    },
  },
  computed: {},
};
</script>
