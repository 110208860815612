import { reactive } from 'vue';

export default {
  debug: true,
  state: reactive({
    source: {},
    english: {},
    locale: {},
    editor: {},
    langCodes:
      [
        {
          localeCode: 'id_id',
          displayName: 'Indonesian - Indonesia',
        },
        {
          localeCode: 'da_dk',
          displayName: 'Danish - Denmark',
        },
        {
          localeCode: 'de_de',
          displayName: 'German - Germany',
        },
        {
          localeCode: 'en_gb',
          displayName: 'English - United Kingdom',
        },
        {
          localeCode: 'en_us',
          displayName: 'English - United States',
        },
        {
          localeCode: 'es_es',
          displayName: 'Spanish - Spain',
        },
        {
          localeCode: 'es_mx',
          displayName: 'Spanish - Mexico',
        },
        {
          localeCode: 'fr_ca',
          displayName: 'French - Canada',
        },
        {
          localeCode: 'fr_fr',
          displayName: 'French - France',
        },
        {
          localeCode: 'it_it',
          displayName: 'Italian - Italy',
        },
        {
          localeCode: 'hu_hu',
          displayName: 'Hungarian - Hungary',
        },
        {
          localeCode: 'nl_nl',
          displayName: 'Dutch - The Netherlands',
        },
        {
          localeCode: 'nb_no',
          displayName: 'Norwegian (Bokmål) - Norway',
        },
        {
          localeCode: 'pl_pl',
          displayName: 'Polish - Poland',
        },
        {
          localeCode: 'pt_br',
          displayName: 'Portuguese - Brazil',
        },
        {
          localeCode: 'pt_pt',
          displayName: 'Portuguese - Portugal',
        },
        {
          localeCode: 'sk_sk',
          displayName: 'Slovak - Slovakia',
        },
        {
          localeCode: 'fi_fi',
          displayName: 'Finnish - Finland',
        },
        {
          localeCode: 'sv_se',
          displayName: 'Swedish - Sweden',
        },
        {
          localeCode: 'tr_tr',
          displayName: 'Turkish - Turkey',
        },
        {
          localeCode: 'cs_cz',
          displayName: 'Czech - Czech Republic',
        },
        {
          localeCode: 'el_gr',
          displayName: 'Greek - Greece',
        },
        {
          localeCode: 'bg_bg',
          displayName: 'Bulgarian - Bulgaria',
        },
        {
          localeCode: 'ru_ru',
          displayName: 'Russian - Russia',
        },
        {
          localeCode: 'ja_jp',
          displayName: 'Japanese - Japan',
        },
        {
          localeCode: 'zh_chs',
          displayName: 'Chinese (Simplified)',
        },
        {
          localeCode: 'zh_cht',
          displayName: 'Chinese (Traditional)',
        },
        {
          localeCode: 'ko_kr',
          displayName: 'Korean - Korea',
        },
      ],
  }),
  methods: {
    setLang(lang, contents) {
      this[lang] = contents;
    },
    hasSession() {
      return this.state.editor.session;
    },
    getLangCode() {
      return this.state.locale.langCode;
    },
  },
};
